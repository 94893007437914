import React from 'react';
import { StaticQuery, graphql, Link } from "gatsby"

export default function Footer() {
  return (
    <StaticQuery
   	 query={graphql`
       query {
		 	wordpressWpApiMenusMenusItems(name: {eq: "footer"}) {
			    items {
			      	title
					object_id
					type
					url
					object_slug
					target
			    }
			  }
		  }
    `}
    render={data=>(<footer id="footer">

      <ul className="copyright">
		{
			data.wordpressWpApiMenusMenusItems.items.map(item=>(
				
				<li key={item.object_id}>
					{
						item.type=== 'custom' ? 
							<a href={item.url} target={item.target || '_self'}>{item.title}</a> :
		          			<Link to={'/' + item.object_slug} target={item.target || '_self'}>{item.title}</Link>
					}
		        </li>
			)
			
			
			)
		}        


      </ul>
    </footer>)} />
  );
}
