import React, { useState } from 'react';
import Nav from './Nav';
import { Link} from 'gatsby';
import Img from "gatsby-image"


export default function SideBar({ fullMenu, title, logo, logoXS, subsInView }) {
	
	const [headerOpen, toggleHeader] = useState(false);
  const sources = [
    logoXS, 
    {
      ...logo,
      media: `(min-width: 768px)`
    }
  ];
	
  return (
    <header id="header" className={`${fullMenu ? '' : 'alt'} ${subsInView ? 'subs-in-view' : ''}`}>
      <h1 className={`${subsInView ? 'subs-in-view' : ''}`}>
        <Link to="/">
          <Img fixed={sources}/>
        </Link>
      </h1>
      <div className={`${headerOpen ? 'is-menu-visible' : ' '}`}>
        <Nav onMenuToggle={() => toggleHeader(!headerOpen)} />
      </div>
    </header>
  );
}
