import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import '../assets/sass/main.scss';

import Img from "gatsby-image"

import Footer from './Footer';
import SideBar from './Sidebar';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreloaded: true,
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { children, fullMenu, subsInView, background} = this.props;
    const { isPreloaded } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            wordpressSiteMetadata {
		     	name      
		    }
 			allImageSharp {
	          edges {
	            node {
	              fluid(maxWidth: 1000, maxHeight: 250, duotone: { highlight: "#000000", shadow: "#000000", opacity: 50 }) {
	                ...GatsbyImageSharpFluid
	                originalName
	              }

	            }
	          }
	        }


			bgD: imageSharp(original: {src: {regex: "/\/Herrenmoos-Facebook-Profilbild_2020_bearbeitet-1-2/"}}) {
				 fluid(maxWidth: 1000, duotone: { highlight: "#000000", shadow: "#000000", opacity: 50 }) {
	                ...GatsbyImageSharpFluid	                
	              }
			 }
			bgM: imageSharp(original: {src: {regex: "/\/Herrenmoos-Facebook-Profilbild_2020_bearbeitet-1-2/"}}) {
				 fluid(maxWidth: 400, duotone: { highlight: "#000000", shadow: "#000000", opacity: 50 }) {
	                ...GatsbyImageSharpFluid	                
	              }
			 }

			logo: file(relativePath: {eq: "test.png"}) {
			    childImageSharp {
			      fixed(width: 170) {
			        src
					...GatsbyImageSharpFixed
			      }
			    }
			  }	

			logoXS: file(relativePath: {eq: "test.png"}) {
			    childImageSharp {
			      fixed(width: 50) {
			        src
					...GatsbyImageSharpFixed
			      }
			    }
			  }	
          }
		
	      
	   

        `}
        render={data => {
		/*	
			const image = data.allImageSharp.edges.find(
		        edge => edge.node.fluid.originalName === background
		      )	
*/
			const sources = data.bgD ? [
				data.bgD.fluid,
				{
					...data.bgM.fluid,
					media: `(max-width: 330px)`
				}
			] : null
			
			
			return (
			<div
              className={
                isPreloaded
                  ? 'landing main-body is-preload'
                  : 'landing main-body'
              }
            >
				<div id="page-wrapper">
				
					{sources && <Img fluid={sources} className="fix-bg" />}
	                <SideBar fullMenu={fullMenu} title={data.wordpressSiteMetadata.name} logo={data.logo.childImageSharp.fixed} logoXS={data.logoXS.childImageSharp.fixed} subsInView={subsInView} />
	                {children}

	                <Footer />
				</div>
            </div>
        )}}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
