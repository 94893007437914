import React from 'react';
import { StaticQuery, graphql, Link } from "gatsby"

import Scroll from '../components/Scroll';

export default function Nav({ onMenuToggle = () => {} }) {
  return (
     <StaticQuery
   	 query={graphql`
       query {
		 	wordpressWpApiMenusMenusItems(name: {eq: "Hauptmenü"}) {
			    items {
			      	title
					object_id
			    }
			  }
		  }
    `}
    render={data=>(<nav id="nav">
      <ul>
        <li className="special">
          <a
            href="#menu"
            onClick={e => {
              e.preventDefault();
              onMenuToggle();
            }}
            className="menuToggle"
          >
            <span>Menu</span>
          </a>
          <div id="menu">
            <ul>
			{data.wordpressWpApiMenusMenusItems.items.map(item=>{
				const a =  <Link to={'/#p' + item.object_id}>
			                {item.title}
			              </Link>
				return (
				<li key={item.object_id}>
				{
					typeof window !== 'undefined' && window.location.pathname === '/' ?
					 <Scroll type="id" element={item.title === 'Start' ? 'banner' : 'p' + item.object_id} clickFunc={onMenuToggle}>
			             {a}
		            </Scroll>
					: 
					a
				}
	              </li>
					
				)})
				}              
            </ul>
            <a
              className="close"
              onClick={e => {
                e.preventDefault();
                onMenuToggle();
              }}
              href="#menu"
            >
              {''}
            </a>
          </div>
        </li>
      </ul>
    </nav>)} />
  );
}